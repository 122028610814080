 <template>
        <div class="page">
            	<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
             <el-button type="primary" @click="project_add">添加</el-button>
             <el-button type="primary" @click="get_page_data">查询</el-button>
		</el-form>
             
             <div class="zwb_list">
               
                 <div class="tab_height">
                    <el-table :data="list" :border="true" :stripe="true" size="small" height="800px">
                        <el-table-column label="项目名称" width="220">
                            <template slot-scope="scope">
                                <div>{{scope.row.item_name}}</div>
                            </template>
                        </el-table-column>
                       <el-table-column label="发车条件" >
                            <template slot-scope="scope">
                                <div>{{scope.row.condition_of_tord_arrived_text}}</div>
                            </template>
                        </el-table-column>
                       <el-table-column label="发车凭证" >
                            <template slot-scope="scope">
                                <div>{{scope.row.truck_tord_start_voucher_upl_mode_text}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="到货条件" width="120">
                            <template slot-scope="scope">
                                <div>{{scope.row.condition_of_tord_arrived_text}}</div>
                            </template>
                        </el-table-column>
                       <el-table-column label="到货凭证" width="120">
                            <template slot-scope="scope">
                                <div>{{scope.row.truck_tord_arrived_voucher_upl_mode_text}}</div>
                            </template>
                        </el-table-column>
                       <el-table-column label="最大单价" width="120">
                            <template slot-scope="scope">
                                <div>{{scope.row.freight_unit_price_max}}元/公里</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="120">
                            <template slot-scope="scope">
                            
                                <el-button  @click="edit(scope.row)" size="mini" type="text">编辑</el-button>

                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
              <!-- 添加弹窗 -->
            <Add :is_show="project_data.is_show"  @success="get_page_data"></Add>

            <!-- 编辑弹窗 -->
            <Edit :is_show="project_edit.is_show" :data="project_edit.data" @success="get_page_data"></Edit>
        </div>
</template>

<script>
import Add from './add.vue'
import Edit from './edit.vue'
import {mapState} from 'vuex'
	export default {
        components:{
            Add,
            Edit
        },
		data() {
			return {
                list:[],
                //添加数据
                project_data:{
                    is_show:0,
                },
                //编辑数据
                project_edit:{
                    is_show:0,
                    data:{}
                },

                is_show_in_page:false
			}
		},
        created() {
			
			//读取页面数据
			this.get_page_data()
		},
        computed:{
			...mapState(["user_info"])
		},
		methods: {
            //编辑项目
            edit(item){
                this.project_edit.is_show ++
                this.project_edit.data = item
            },
            //添加项目
            project_add(){
                this.project_data.is_show ++
            },
            //读取数据
            get_page_data(){
                console.log(this.user_info,777)
                this.$my.net.req({
                    data:{
                        mod:'app_user',
                        ctr:'company_item_list',
                    },callback:(data)=>{
                        //预处理
                        for(let item of data.list){

                            //到货条件(1:无,2:使用经纬度校验,3:时间间隔30分钟)
                            switch(item.condition_of_tord_arrived){
								case '1':item.condition_of_tord_arrived_text='无';break;
								case '2':item.condition_of_tord_arrived_text='使用经纬度校验';break;
                                case '3':item.condition_of_tord_arrived_text='时间间隔30分钟';break;
							}

                            //发车条件(1:无,2:使用经纬度校验)
                            switch(item.condition_of_tord_start){
								case '1':item.condition_of_tord_arrived_text='无';break;
								case '2':item.condition_of_tord_arrived_text='使用经纬度校验';break;
							}

                            //额外服务费计算方式(1:除法模式,2:乘法模式)
                            switch(item.extra_service_charge_type){
								case '1':item.extra_service_charge_type_text='除法模式';break;
								case '2':item.extra_service_charge_type_text='乘法模式';break;
							}

                           	//到货凭证上传模式(1:自愿,2:自动打开上传界面,3:强制上传)
                            switch(item.truck_tord_arrived_voucher_upl_mode){
								case '1':item.truck_tord_arrived_voucher_upl_mode_text='自愿';break;
								case '2':item.truck_tord_arrived_voucher_upl_mode_text='自动打开上传界面';break;
                                case '2':item.truck_tord_arrived_voucher_upl_mode_text='强制上传';break;
							}

                            //发车凭证上传模式(1:自愿,2:自动打开上传界面,3:强制上传)
                            switch(item.truck_tord_start_voucher_upl_mode){
								case '1':item.truck_tord_start_voucher_upl_mode_text='自愿';break;
								case '2':item.truck_tord_start_voucher_upl_mode_text='自动打开上传界面';break;
                                case '3':item.truck_tord_start_voucher_upl_mode_text='强制上传';break;
							}
                        }
                        this.list = data.list
                    }
                })
            }
		}
	}
</script>
<style lang="scss" scoped>
.zwb_list{
    margin-top: 10px;
}
.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 140px);
	}
	.el_form_item{
		width: 18%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin-left: 0;
	}
	.link{
		cursor:pointer;
		color:blue;
		border-bottom:1px solid blue;
	}
</style>