 <template>
	<el-dialog title="添加项目" top="1vh" width="1080px" :visible.sync="is_show_in_page"  :modal="false" @close="close">
		
		<!-- 表单 -->
		<el-form label-width="80px" style="margin:-15px 0 20px 0">


			<div class="big_tit" style="margin:0">项目信息</div>
			<div class="tab1_inner" style="margin-bottom:20px">
				<el-form-item  class="el_form_item" label="项目名称" style="width:240px">
					<el-input v-model="form.item_name">
					
					</el-input>
				</el-form-item>
			</div>
			<div class="big_tit" style="margin:0">发车/到货</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="发车条件" style="width:250px">
					<el-select class="el_inner_width" v-model="form.condition_of_tord_start">
						<el-option label="无" value="1"></el-option>
						<el-option label="区划校验" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="发车凭证" style="width:250px">
					<el-select  class="el_inner_width" v-model="form.truck_tord_start_voucher_upl_mode">
						<el-option label="自愿上传" value="1"></el-option>
						<el-option label="提示上传" value="2"></el-option>
						<el-option label="强制上传" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="到货条件" style="width:250px">
					<el-select  class="el_inner_width" v-model="form.condition_of_tord_arrived">
						<el-option label="无" value="1"></el-option>
						<el-option label="区划校验" value="2"></el-option>
						<el-option label="间隔30分钟" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="到货凭证" style="width:250px">
					<el-select  class="el_inner_width" v-model="form.truck_tord_arrived_voucher_upl_mode">
						<el-option label="自愿上传" value="1"></el-option>
						<el-option label="提示上传" value="2"></el-option>
						<el-option label="强制上传" value="3"></el-option>
					</el-select>
				</el-form-item>
			</div>

			<div class="big_tit">允许最大单价/预付款最大比例(0 - 100%)</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="最大单价" style="width:490px">
					<el-input v-model="form.freight_unit_price_max">
						<el-button slot="append">元/公里</el-button>
						
					</el-input>
				</el-form-item>
			</div>


		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub()">提交</el-button>
		</div>
	</el-dialog>
	
</template>

<script>
	export default {
		props:{
			is_show:Number,
		},
		data() {
			return {
				//是否显示
				is_show_in_page:false,


				//货主参数
				form:{
                    //项目名称
					item_name:'',



					//允许运费单价最大值(单位:元/公里)
					freight_unit_price_max:'',//

					//运费未到货时付款总量占总运费最大比例
					freight_not_arrived_pay_percent_max:'',//


					//发车条件(1:无,2:使用经纬度校验)
					condition_of_tord_start:'',//

					//到货条件(1:无,2:使用经纬度校验,3:时间间隔30分钟)
					condition_of_tord_arrived:'',//

					//发车凭证上传模式(1:自愿,2:自动打开上传界面,3:强制上传)
					truck_tord_start_voucher_upl_mode:'',//

					//到货凭证上传模式(1:自愿,2:自动打开上传界面,3:强制上传)
					truck_tord_arrived_voucher_upl_mode:'',//
				},

			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					//页面标题
					// this.page_title=`${this.company_name}的参数`

					//打开弹出层
					this.is_show_in_page=true;

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			},
			mark(new_data){
				this.mark = new_data
			},
		},
		methods: {

            close(){
                //货主参数
				this.form={
                    //项目名称
					item_name:'',



					//允许运费单价最大值(单位:元/公里)
					freight_unit_price_max:'',//

					//运费未到货时付款总量占总运费最大比例
					freight_not_arrived_pay_percent_max:'',//


					//发车条件(1:无,2:使用经纬度校验)
					condition_of_tord_start:'',//

					//到货条件(1:无,2:使用经纬度校验,3:时间间隔30分钟)
					condition_of_tord_arrived:'',//

					//发车凭证上传模式(1:自愿,2:自动打开上传界面,3:强制上传)
					truck_tord_start_voucher_upl_mode:'',//

					//到货凭证上传模式(1:自愿,2:自动打开上传界面,3:强制上传)
					truck_tord_arrived_voucher_upl_mode:'',//
				}
            },
            //提交
            sub(){
                this.$my.net.req({
                    data:{
                          mod:'app_user',
                        ctr:'company_item_set',
                        ...this.form
                    },callback:(data)=>{
                       	this.$my.other.msg({
                            type:'success',
                            str:'添加成功'
						});
                        //关闭弹出层
                        this.is_show_in_page=false;

                        this.$emit("success")
                    }
                  
                })
            },
		}
	}
</script>
<style lang="scss" scoped>
.upl {
  .img_area {
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 5px;
    .show {
      margin: auto;
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
    .icon {
      text-align: center;
      display: block;
      font-size: 100px;
      height: 500px;
      line-height: 500px;
    }
  }
  .mark_area {
    border: 1px solid #ccc;
    padding: 5px;
    width: 448px;
    margin-top: 10px;
    height: 100px;
    outline: none;
    font-size: 16px;
  }
  .mark_area::placeholder {
    font-size: 20px;
    line-height: 100px;
    text-align: center;
  }
  .btn_area {
    text-align: center;
    margin-top: 15px;
  }
}
	.el_form_item {
		margin: 0;
		.img_btn {
			border: 1px solid #bbb;
			border-radius: 5px;
			cursor: pointer;
			padding: 5px;
			height: 300px;
			overflow: hidden;
			.btn {
			text-align: center;
			line-height: 290px;
			font-size: 14px;
			}

			.img {
			position: relative;
			display: block;
			height: 290px;
			}
		}
		  .bottom_text {
			margin-top: 5px;
			text-align: center;
		}
	}
	
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	.tord_real_time_add_bottom_btns{
		margin-top: 40px;
		text-align: center;
	}
	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}
	.el-dialog-s{
		z-index: 11;
	}
	.company_contract{
		display: flex;
		justify-content: space-around;
	}
	#pdf_upload{
		margin-right: 100%;
	}
</style>